<template>
    <div class="root">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{$route.name}}</span>
            </div>

            <!-- 表格 -->
            <el-table :data="tableData" stripe style="width: 100%" v-loading="tableloading">
                <!-- 表格数据列 -->
                <el-table-column prop="id" label="ID" width="80"></el-table-column>
                <el-table-column prop="account" label="邮箱"></el-table-column>
                <el-table-column label="订阅平台" min-width="350">
                    <template slot-scope="item">
                        <el-tag size="small" v-for="(name, i) in item.row.subscribe_source_name" :key="'source' + i">{{name}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="创建日期">
                    <template slot-scope="item">
                        {{base.formatTime(new Date(item.row.create_time))}}
                    </template>
                </el-table-column>
            </el-table>

            <!-- 表格分页 -->
            <div class="pager">
                <el-pagination background layout="prev, pager, next" page-size="20" :total="pageTotal" :current-page="searchData.page" @current-change="changePage"></el-pagination>
            </div>

        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 搜索数据
            searchData: {
                page: 1,
                size: 20
            },
            // 表格数据
            pageTotal: 0,
            tableloading: true,
            tableData: [],
            sourceData: [],
            sourceObj: {},
        }
    },
    created() {
        this.loadSource()
    },
    methods: {
        // 表格请求
        loadTable() {
            let that = this
            this.ajax({
                url: '/user/list',
                data: that.searchData,
                complete() {
                    that.tableloading = false
                },
                success(res) {
                    for (let i = 0; i < res.data.list.length; i++) {
                        if (res.data.list[i].subscribe_source !== '') {
                            res.data.list[i].subscribe_source_name = []
                            let sourceArr = res.data.list[i].subscribe_source.split(',')
                            for (let k = 0; k < sourceArr.length; k++) {
                                res.data.list[i].subscribe_source_name.push(that.sourceObj[sourceArr[k]])
                            }
                        }
                    }
                    that.tableData = res.data.list
                    that.pageTotal = res.data.count
                },
                error(res) {
                    that.$message.error(res.message);
                },
            })
        },
        // 所有平台请求
        loadSource() {
            let that = this
            that.ajax({
                url: '/hot/source_all',
                beforeSend() {
                    that.tableloading = true
                },
                success(res) {
                    for (let i = 0; i < res.data.length; i++) {
                        that.sourceObj[res.data[i].id] = res.data[i].name
                    }
                    that.sourceData = res.data
                    that.loadTable()
                },
                error(res) {
                    that.$message.error(res.message);
                },
            })
        },
        // 翻页
        changePage(page) {
            this.searchData.page = page
            this.loadTable()
        },
    }
}
</script>

<style lang="less" scoped>
.pager {
    padding-top: 20px;
    text-align: right;
}

.el-tag {
    margin: 0 5px 5px 0;
}
</style>